import React from 'react';
import './f1-cerere-certificat-urbanism.styles.scss';
import '../../components/sidebar/sidebar.styles.scss';
import { Helmet } from 'react-helmet';

// Forms import
import Input from '../../components/inputs/input.component';

// Sections
import Page from '../../components/page-component/page.component';
import SectiuneBeneficiar from '../sectiuni-formulare/beneficiar.section';
import SectiuneImobilUrbanism from '../sectiuni-formulare/imobil.section.urbanism';


import Sidebar from '../../components/sidebar/Sidebar';
import TipuriDeLucrariFullwidth from "../sectiuni-formulare/tipuri-de-lucrari-fullwidth.section";

const Formular1 = () => {
  return (
    <div className="formular1-layout"> {/* Ensure the layout class name matches f8 */}
          <Helmet>
        <title>Formular 1 - CERERE pentru emiterea certificatului de urbanism</title>

        <meta name="description" content="Completează formulatul de obținere a certificatului de urbanism în format digital." />
        <link rel="canonical" href="https://cerereautorizatiedeconstruire.ro/formular1-CERERE-pentru-emiterea-certificatului-de-urbanism" />
        <meta property="og:title" content="Formular 1 - CERERE pentru emiterea certificatului de urbanism" />
        <meta property="og:description" content="Completează formulatul de obținere a autorizației de construire în format digital." />
        <meta property="og:image" content="https://lusto.ro/wp-content/uploads/2024/07/7396132-mediafax-foto-andreea-alexandru-scaled.jpg" />
        <meta property="og:url" content="https://cerereautorizatiedeconstruire.ro/formular1-CERERE-pentru-emiterea-certificatului-de-urbanism" />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="form-content">
        <div className="page-parent">
          <Page id="pagina-1">
            <p style={{ marginLeft: 5, padding: 0 }}>Către</p>
            <Input defaultValue="" id="catre" name="catre" style={{ width: '300px' }} /> {/* Match width style */}
            <span style={{ fontSize: '8pt', display: 'block', textAlign: 'center', width: '300px' }}>
              {`[conducătorul autorităţii administraţiei publice emitente *]`}
            </span>
            <h1>{`CERERE`}<br/>{`pentru emiterea certificatului de urbanism`}</h1>
            <SectiuneBeneficiar />

              <p>
        în conformitate cu prevederile Legii nr. 50/1991, privind autorizarea executării lucrărilor de
        construcţii, republicată, cu modificările şi completările ulterioare, solicit emiterea certificatului de urbanism în scopul: {" "}
          <Input
            id="scop"
            name="scop"
            style={{ width: "auto" }}
          />
          <Input id="scop" name="scop" style={{ width: '100%' }} /> {/* Ensure full width style */}
          <Input id="scop" name="scop" style={{ width: '100%' }} /> {/* Ensure full width style */}
          
      </p>
            <TipuriDeLucrariFullwidth indentSubCat = {30}/>
            <SectiuneImobilUrbanism />


            <div className="nota-subsol" style={{ marginTop: "0px" }}>
  <div style={{ marginLeft: "40px" }}>
    <sup>*)</sup> Se completează, după caz:
  </div>
  <div style={{ marginLeft: "40px" }}>
    ⎯ preşedintele Consiliului judeţean
  </div>
  <div style={{ marginLeft: "40px" }}>
    ⎯ primarul General al municipiului Bucureşti
  </div>
  <div style={{ marginLeft: "40px" }}>
    ⎯ primarul sectorului ……. al municipiului Bucureşti
  </div>
  <div style={{ marginLeft: "40px" }}>
    ⎯ primarul municipiului
  </div>
  <div style={{ marginLeft: "40px" }}>
    ⎯ primarul oraşului
  </div>
  <div style={{ marginLeft: "40px" }}>
    ⎯ primarul comunei
  </div>
  <div style={{ marginLeft: "40px" }}>
    <sup>**)</sup> Se introduce „X” în casetă şi se anulează ce nu corespunde
  </div>
  <div style={{ marginLeft: "40px" }}>
    <sup>***)</sup> Se face precizarea, după caz
  </div>
</div>

          </Page>
        </div>
      </div>
      <Sidebar /> {/* Sidebar placed correctly */}
    </div>
  );
}

export default Formular1;
