import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.scss';
import { Helmet } from 'react-helmet';

const HomePage = () => {
  const handleComingSoon = (event) => {
    event.preventDefault();
    alert("Coming Soon!");
  };

  return (
    <div className="home-page">
      <Helmet>
        <title>Formulare Digitale Legea 50</title>
        <meta name="description" content="Autorizație de Construire și Certificat de Urbanism" />
        <link rel="canonical" href="https://cerereautorizatiedeconstruire.ro" />
        <meta property="og:title" content="Formulare Digitale Legea 50 in format digital" />
        <meta property="og:description" content="Completeaza toate cererile necesare pentru autorizarea lucrarilor de constructie in format digital." />
        <meta property="og:image" content="https://lusto.ro/wp-content/uploads/2024/07/7396132-mediafax-foto-andreea-alexandru-scaled.jpg" />
        <meta property="og:url" content="https://cerereautorizatiedeconstruire.ro/formulare-digitale-autorizatie-de-constructie-si-urbanism" />
        <meta property="og:type" content="website" />
      </Helmet>
 <div className="home-page-container">
      <div className="banner">
        <div className="banner-content">
          <h1 className="banner-heading">Formulare Legea 50</h1>
          <h2 className="banner-subheading">Autorizații de Construire și Certificat de Urbanism</h2>
        </div>
      </div>
      <div className="explanatory-text">
        <h4>
          Completeaza toate cererile necesare pentru autorizarea lucrarilor de constructie in format digital.
          Cand ai terminat de completat, tipareste sau salveaza in format .pdf <br />
          Datele specifice se salveaza local, in browserul tau.
        </h4>
      </div>
      <div className="form-section">
        <h3>Formulare</h3>
        <div className="form-buttons">
          <Link to="/formular1-CERERE-pentru-emiterea-certificatului-de-urbanism" className="form-button">
            Formular 1: Cerere pentru emiterea certificatului de urbanism
          </Link>
          <a href="/formular-2" className="form-button unavailable" onClick={handleComingSoon}>
            Formular 2: Cerere pentru emiterea autorizației de construire
          </a>
          <a href="/formular-3" className="form-button unavailable" onClick={handleComingSoon}>
            Formular 3: Cerere pentru prelungirea valabilității certificatului de urbanism
          </a>
          <a href="/formular-4" className="form-button unavailable" onClick={handleComingSoon}>
            Formular 4: Certificat de urbanism
          </a>
          <a href="/formular-5" className="form-button unavailable" onClick={handleComingSoon}>
            Formular 5: Autorizație de construire/desființare
          </a>
          <a href="/formular-6" className="form-button unavailable" onClick={handleComingSoon}>
            Formular 6: Aviz structurii de specialitate pentru eliberarea certificatului de urbanism
          </a>
          <a href="/formular-7" className="form-button unavailable" onClick={handleComingSoon}>
            Formular 7: Cerere pentru prelungirea autorizației de construire/desființare
          </a>
          <Link to="/formular8-CERERE-pentru-emiterea-autorizației-de-construire/desființare" className="form-button">
            Formular 8: Cerere pentru emiterea autorizației de construire/desființare
          </Link>
          <a href="/formular-9" className="form-button unavailable" onClick={handleComingSoon}>
            Formular 9: Autorizație de funcționare
          </a>
          <a href="/formular-10" className="form-button unavailable" onClick={handleComingSoon}>
            Formular 10: Cerere pentru emiterea avizului de urbanism
          </a>
          <a href="/formular-11" className="form-button unavailable" onClick={handleComingSoon}>
            Formular 11: Autorizație de construire/desființare
          </a>
          <a href="/formular-12" className="form-button unavailable" onClick={handleComingSoon}>
            Formular 12: Cerere pentru prelungirea avizului de urbanism
          </a>
          <a href="/formular-13" className="form-button unavailable" onClick={handleComingSoon}>
            Formular 13: Cerere pentru emiterea certificatului de edificare
          </a>
          <a href="/formular-14" className="form-button unavailable" onClick={handleComingSoon}>
            Formular 14: Certificat de urbanism de informare
          </a>
          <a href="/formular-15" className="form-button unavailable" onClick={handleComingSoon}>
            Formular 15: Cerere pentru prelungirea certificatului de urbanism
          </a>
          <a href="/formular-16" className="form-button unavailable" onClick={handleComingSoon}>
            Formular 16: Cerere pentru reînnoirea autorizației de construire/desființare
          </a>
        </div>
      </div>
      <div className="annex-section">
        <h3>Anexe</h3>
        <div className="form-buttons">
          <a href="/anexa-1" className="form-button unavailable" onClick={handleComingSoon}>
            Anexa 1: ...
          </a>
          <a href="/anexa-2" className="form-button unavailable" onClick={handleComingSoon}>
            Anexa 2: ...
          </a>
          <a href="/anexa-3" className="form-button unavailable" onClick={handleComingSoon}>
            Anexa 3: ...
          </a>
          <a href="/anexa-4" className="form-button unavailable" onClick={handleComingSoon}>
            Anexa 4: ...
          </a>
          <a href="/anexa-5" className="form-button unavailable" onClick={handleComingSoon}>
            Anexa 5: ...
          </a>
          <a href="/anexa-6" className="form-button unavailable" onClick={handleComingSoon}>
            Anexa 6: ...
          </a>
          <a href="/anexa-7" className="form-button unavailable" onClick={handleComingSoon}>
            Anexa 7: ...
          </a>
          <a href="/anexa-8" className="form-button unavailable" onClick={handleComingSoon}>
            Anexa 8: ...
          </a>
        </div>
      </div>
    </div>
    </div>
  );
}

export default HomePage;
