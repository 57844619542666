import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.scss';

const Sidebar = () => {
  const handleComingSoon = (event) => {
    event.preventDefault();
    alert("Coming Soon!");
  };

  return (
    <div className="sidebar">
      <h3>Formulare</h3>
      <div className="form-buttons">
      <Link to="/formular1-CERERE-pentru-emiterea-certificatului-de-urbanism" className="form-button">
          Formular 1: Cerere pentru emiterea certificatului de urbanism
        </Link>
        <a href="/formular-2" className="form-button unavailable" onClick={handleComingSoon}>
          Formular 2
        </a>
        <a href="/formular-3" className="form-button unavailable" onClick={handleComingSoon}>
          Formular 3
        </a>
        <a href="/formular-4" className="form-button unavailable" onClick={handleComingSoon}>
          Formular 4
        </a>
        <a href="/formular-5" className="form-button unavailable" onClick={handleComingSoon}>
          Formular 5
        </a>
        <a href="/formular-6" className="form-button unavailable" onClick={handleComingSoon}>
          Formular 6
        </a>
        <a href="/formular-7" className="form-button unavailable" onClick={handleComingSoon}>
          Formular 7
        </a>
        <Link to="/formular8-CERERE-pentru-emiterea-autorizației-de-construire/desființare" className="form-button">
            Formular 8: Cerere pentru emiterea autorizației de construire/desființare
          </Link>
        <a href="/formular-9" className="form-button unavailable" onClick={handleComingSoon}>
          Formular 9
        </a>
        <a href="/formular-10" className="form-button unavailable" onClick={handleComingSoon}>
          Formular 10
        </a>
        <a href="/formular-11" className="form-button unavailable" onClick={handleComingSoon}>
          Formular 11
        </a>
        <a href="/formular-12" className="form-button unavailable" onClick={handleComingSoon}>
          Formular 12
        </a>
        <a href="/formular-13" className="form-button unavailable" onClick={handleComingSoon}>
          Formular 13
        </a>
        <a href="/formular-14" className="form-button unavailable" onClick={handleComingSoon}>
          Formular 14
        </a>
        <a href="/formular-15" className="form-button unavailable" onClick={handleComingSoon}>
          Formular 15
        </a>
        <a href="/formular-16" className="form-button unavailable" onClick={handleComingSoon}>
          Formular 16
        </a>
      </div>
      <h3>Anexe</h3>
      <div className="form-buttons">
        <a href="/anexa-1" className="form-button unavailable" onClick={handleComingSoon}>
          Anexa 1
        </a>
        <a href="/anexa-2" className="form-button unavailable" onClick={handleComingSoon}>
          Anexa 2
        </a>
        <a href="/anexa-3" className="form-button unavailable" onClick={handleComingSoon}>
          Anexa 3
        </a>
        <a href="/anexa-4" className="form-button unavailable" onClick={handleComingSoon}>
          Anexa 4
        </a>
        <a href="/anexa-5" className="form-button unavailable" onClick={handleComingSoon}>
          Anexa 5
        </a>
        <a href="/anexa-6" className="form-button unavailable" onClick={handleComingSoon}>
          Anexa 6
        </a>
        <a href="/anexa-7" className="form-button unavailable" onClick={handleComingSoon}>
          Anexa 7
        </a>
        <a href="/anexa-8" className="form-button unavailable" onClick={handleComingSoon}>
          Anexa 8
        </a>
      </div>
    </div>
  );
};

export default Sidebar;
