import React from "react";
import Checkbox from "../../components/checkbox/checkbox.component";
import Section from "../../components/section/section.component";
import COLORS from "../../theme/colors";
import Input from '../../components/inputs/input.component';
import "./tipuri-de-lucrari-fullwidth.styles.scss";

const TipuriDeLucrariFullwidth = () => {
  const renderLeftColumn = () => (
    <>
      <Checkbox margin="0px" id="v-1">
        {"1. Elaborarea documentaţiei pentru autorizarea executării lucrărilor de construcţii, în conformitate cu prevederile art.3 alin.(1) din Lege, privind:"}
      </Checkbox>
      <Checkbox margin="40px" id="v-1-1">
        {"1.1. Lucrări de construire"}
      </Checkbox>
      <Checkbox margin="40px" id="v-1-2">
        {"1.2. Lucrări de desfiinţare"}
      </Checkbox>
      <Checkbox margin="40px" id="v-a">
        {"a) lucrări de construire, reconstruire, consolidare, modificare, extindere, reabilitare, schimbare de destinaţie sau de reparare a construcţiilor de orice fel, precum şi a instalaţiilor aferente acestora, cu excepţia celor prevăzute la art.12 din Legea nr.50/1991;"}
      </Checkbox>
      <Checkbox margin="40px" id="v-b">
        {"b) lucrări de construire, reconstruire, extindere, reparare, consolidare, protejare, restaurare, conservare, precum şi orice alte lucrări, indiferent de valoarea lor, care urmează să fie efectuate la construcţii reprezentând monumente istorice, stabilite potrivit legii;"}
      </Checkbox>
      <Checkbox margin="40px" id="v-c">
        {"c) lucrări de construire, reconstruire, modificare, extindere, reparare, modernizare şi reabilitare privind căile de comunicaţie de orice fel, drumurile forestiere, lucrările de artă, reţelele şi dotările tehnico-edilitare, lucrările hidrotehnice, amenajările de albii, lucrările de îmbunătăţiri funciare, lucrările de instalaţii de infrastructură, lucrările pentru noi capacităţi de producere, transport, distribuţie a energiei electrice şi/sau termice, precum şi de reabilitare şi retehnologizare a celor existente;"}
      </Checkbox>
      <Checkbox margin="40px" id="v-d">
        {"d) împrejmuiri şi mobilier urban, amenajări de spaţii verzi, parcuri, pieţe şi alte lucrări de amenajare a spaţiilor publice;"}
      </Checkbox>
    </>
  );

  const renderRightColumn = () => (
    <>
      <Checkbox margin="40px" id="v-e">
        {"e) lucrări de foraje şi excavări necesare pentru efectuarea studiilor geotehnice şi a prospecţiunilor geologice, proiectarea şi deschiderea exploatărilor de cariere şi balastiere, a sondelor de gaze şi petrol, precum şi a altor exploatări de suprafaţă sau subterane;"}
      </Checkbox>
      <Checkbox margin="40px" id="v-f">
        {"f) lucrări, amenajări şi construcţii cu caracter provizoriu, necesare în vederea organizării executării lucrărilor, în condiţiile prevăzute la art.7 alin.(11) din Legea nr.50/1991;"}
      </Checkbox>
      <Checkbox margin="40px" id="v-g">
        {"g) organizarea de tabere de corturi, căsuţe sau rulote;"}
      </Checkbox>
      <Checkbox margin="40px" id="v-h">
        {"h) lucrări de construcţii cu caracter provizoriu, chioşcuri, tonete, cabine, spaţii de expunere situate pe căile şi spaţiile publice, corpuri şi panouri de afişaj, firme şi reclame, precum şi anexele gospodăreşti ale exploataţiilor agricole situate în extravilan;"}
      </Checkbox>
      <Checkbox margin="40px" id="v-i">
        {"i) cimitire ⎯ noi şi extinderi."}
      </Checkbox>

      <Checkbox margin="0px" id="v-2">
      {"2. Operaţiuni notariale privind circulaţia imobiliară:"}
</Checkbox>
<div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
  <div style={{ flex: '1 1 calc(33.333% - 10px)', marginBottom: '-7px' }}>
    <Checkbox margin="40px" id="vanzari" style={{ fontSize: '12px' }}>
      {"vânzări"}
    </Checkbox>
  </div>
  <div style={{ flex: '1 1 calc(33.333% - 10px)', marginBottom: '-7px' }}>
    <Checkbox margin="10px" id="cumparari" style={{ fontSize: '12px' }}>
      {"cumpărări"}
    </Checkbox>
  </div>
  <div style={{ flex: '1 1 calc(33.333% - 10px)', marginBottom: '-7px' }}>
    <Checkbox margin="10px" id="concesionari" style={{ fontSize: '12px' }}>
      {"concesionări"}
    </Checkbox>
  </div>
  <div style={{ flex: '1 1 calc(33.333% - 10px)', marginBottom: '-7px' }}>
    <Checkbox margin="40px" id="cesionari" style={{ fontSize: '12px' }}>
      {"cesionări"}
    </Checkbox>
  </div>
  <div style={{ flex: '1 1 calc(33.333% - 10px)', marginBottom: '-7px' }}>
    <Checkbox margin="10px" id="dezmembari" style={{ fontSize: '12px' }}>
      {"dezmembrări"}
    </Checkbox>
  </div>
  <div style={{ flex: '1 1 calc(33.333% - 10px)', marginBottom: '-7px' }}>
    <Checkbox margin="10px" id="parcelari" style={{ fontSize: '12px' }}>
      {"parcelări"}
    </Checkbox>
  </div>
  <div style={{ flex: '1 1 calc(33.333% - 10px)', marginBottom: '1px' }}>
    <Checkbox margin="40px" id="comasari" style={{ fontSize: '12px' }}>
      {"comasări"}
    </Checkbox>
  </div>
  <div style={{ flex: '1 1 calc(33.333% - 10px)', marginBottom: '1px' }}>
    <Checkbox margin="10px" id="partaje" style={{ fontSize: '12px' }}>
      {"partaje"}
    </Checkbox>
  </div>
  <div style={{ flex: '1 1 calc(33.333% - 10px)', marginBottom: '1px' }}>
    <Checkbox margin="10px" id="succesiuni" style={{ fontSize: '12px' }}>
      {"succesiuni etc."}
    </Checkbox>
  </div>
</div>



      <Checkbox margin="0px" id="v-3">
        {"3. Adjudecarea prin licitaţie a proiectării lucrărilor publice (denumire)"} {" "}
          <Input
            id="licitatie"
            name="licitatie"
            style={{ width: "auto" }}
          />
                   <Input id="licitatie" name="licitatie" style={{ width: '100%' }} /> {/* Ensure full width style */}
      </Checkbox>
      <Checkbox margin="0px" id="v-4">

        {"4. Cereri în justiţie"}
      </Checkbox>
      <Checkbox margin="0px" id="v-5">
        {"5. Alte scopuri prevăzute de lege (definire)"}
      </Checkbox>
      <Input id="altscop" name="altscop" style={{ width: '100%' }} /> {/* Ensure full width style */}
      <Input id="altscop" name="altscop" style={{ width: '100%' }} /> {/* Ensure full width style */}
    </>
    
  );

  return (
    <Section color={COLORS.orange}>
      <div className="grid-container-fullwidth">
        <div className="left-column">
          {renderLeftColumn()}
        </div>
        <div className="right-column">
          {renderRightColumn()}
        </div>
      </div>
    </Section>
  );
};

export default TipuriDeLucrariFullwidth;
