import React from "react";
import Input from "../../components/inputs/input.component";
import TextLineTrough from "../../components/linetrough-text/text-linetrough.component";
import DataSemnatura from '../../components/data-semnatura/data-semnatura.component'
import Section from "../../components/section/section.component";
import COLORS from "../../theme/colors";
import Checkbox from "../../components/checkbox/checkbox.component";

const SectiuneImobil = () => {
  return (
    <Section color={COLORS.blue}>

<p className="indent" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '0px' }}>
  pentru imobilul 
  <span style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '-30px', marginRight: '-30px' }}>
    <Checkbox margin="0px" id="v-a">
      {"teren"}
    </Checkbox>
  </span>
  şi/sau 
  <span style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '-30px', marginRight: '-30px' }}>
    <Checkbox margin="0px" id="v-a">
      {"constructii"}
    </Checkbox>
  </span> 
  , situat în judeţul



        <Input
          id="judet-imobil"
          name="judet-imobil"
          style={{ width: "250px" }}
        />
        </p><p>
        ,

        
        <TextLineTrough words={[" municipiul/", "orașul/", "comuna "]} />
        <Input
          id="localitate-imobil"
          name="localitate-imobil"
          style={{ width: "256px" }}
        />
        , satul
        <Input id="sat-imobil" name="sat-imobil" size="205px" />, sectorul
        <Input
          className="centrat"
          id="sector-imobil"
          name="sector-imobil"
          style={{ width: "20px" }}
        />
        , cod poștal
        <Input
          className="centrat"
          id="cp-imobil"
          name="cp-imobil"
          style={{ width: "50px" }}
        />
        , str.
        <Input
          className="centrat"
          id="str-imobil"
          name="str-imobil"
          style={{ width: "240px" }}
        />
        , nr.
        <Input
          className="centrat"
          id="nr-imobil"
          name="nr-imobil"
          style={{ width: "30px" }}
        />
        , bl.
        <Input
          className="centrat"
          id="bloc-imobil"
          name="bloc-imobil"
          style={{ width: "20px" }}
        />
        , sc.
        <Input
          className="centrat"
          id="scara-imobil"
          name="scara-imobil"
          style={{ width: "20px" }}
        />
        , et.
        <Input
          className="centrat"
          id="etaj-imobil"
          name="etaj-imobil"
          style={{ width: "30px" }}
        />
        , ap.
        <Input
          className="centrat"
          id="ap-imobil"
          name="ap-imobil"
          style={{ width: "30px" }}
        />
        ,
      </p>
      <p className="lista-identificare-teren">

          sau identificat prin <sup style={{ fontSize: 'smaller' }}>3)</sup>{" "}
          <Input id="cf-imobil" name="cf-imobil" style={{ width: "550px" }} />,{" "}
      </p>



      <p style={{ textIndent: '0px', marginLeft: '43px' }}>
  În sprijinul identificării imobilului anexez: <br />
  ⎯ planul cadastral/topografic actualizat la zi, scara 
  <Input
    id="scara-imobil"
    name="scara-imobil"
    style={{ width: "100px", display: 'inline-block', marginLeft: '5px', marginRight: '5px' }} // Adjust the width as needed
  />
  , precum şi extrasul de carte funciară pentru informare, eliberate de OCPI<sup>4)</sup> <br />
  ⎯ 
  <Input
    id="custom-input"
    name="custom-input"
    style={{ width: "600px", display: 'inline-block', marginLeft: '5px', marginRight: '5px' }} // Adjust the width as needed
  /> <br />
  Suprafaţa terenului şi/sau construcţiei pentru care solicit certificatul de urbanism este de 
  <Input
    id="suprafata-imobil"
    name="suprafata-imobil"
    style={{ width: "70px", display: 'inline-block', marginLeft: '5px', marginRight: '5px' }} // Adjust the width as needed
  /> 
  m<sup>2</sup>. <br />
</p>

<div style={{ marginTop: '-110px' }}>
      <DataSemnatura signText={<>Semnătură <sup>5)</sup></>} />
    </div>

    </Section>

    
  );
};

export default SectiuneImobil;
