import React from 'react';
import './privacypolicy.scss';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Politica de Confidențialitate</h1>
      <p>Ultima actualizare: 7 august 2024</p>
      
      <h2>Introducere</h2>
      <p>Această politică de confidențialitate explică modul în care cerereautorizatiedeconstruire.ro ("noi" sau "site-ul nostru") gestionează informațiile atunci când utilizați website-ul nostru. Ne angajăm să protejăm confidențialitatea dumneavoastră și să fim transparenți cu privire la practicile noastre.</p>
      
      <h2>Colectarea și Utilizarea Informațiilor</h2>
      <p>Website-ul nostru nu colectează sau stochează direct nicio informație personală. Nu avem o bază de date conectată la site-ul nostru. Cu toate acestea, unele informații sunt salvate în cache-ul browserului dumneavoastră pentru a îmbunătăți experiența de utilizare.</p>
      
      <h2>Servicii Terțe</h2>
      <p>Utilizăm următoarele servicii Google pe website-ul nostru:</p>
      <ul>
        <li>Google Analytics: Pentru a înțelege cum este utilizat website-ul nostru</li>
        <li>Google AdSense: Pentru afișarea de reclame</li>
        <li>Google Console: Pentru gestionarea și monitorizarea performanței website-ului</li>
      </ul>
      <p>Aceste servicii pot colecta informații conform politicilor lor de confidențialitate. Vă încurajăm să consultați aceste politici pentru mai multe informații despre modul în care gestionează datele dumneavoastră.</p>
      
      <h2>Cookie-uri</h2>
      <p>Website-ul nostru și serviciile terțe pe care le utilizăm pot folosi cookie-uri sau tehnologii similare de urmărire. Puteți gestiona preferințele privind cookie-urile prin setările browserului dumneavoastră.</p>
      
      <h2>Securitatea Datelor</h2>
      <p>Deși nu colectăm date personale direct, luăm măsuri rezonabile pentru a proteja informațiile procesate prin site-ul nostru, inclusiv utilizarea criptării HTTPS.</p>
      
      <h2>Modificări ale Acestei Politici</h2>
      <p>Putem actualiza această politică de confidențialitate din când în când. Vă vom notifica despre orice modificări prin postarea noii politici pe această pagină.</p>
      
      <h2>Contactați-ne</h2>
      <p>Acest website a fost dezvoltat ca un proiect personal și este oferit gratuit. Dacă aveți întrebări despre această politică de confidențialitate, vă rugăm să ne contactați la admin@minerenigma.com.</p>
    </div>
  );
};

export default PrivacyPolicy;