import React from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';

const Footer = () => {
  return (
<footer className="footer">
  <div className="footer-content">
    <a href="/privacy-policy" className="privacy-policy">Privacy Policy</a>
    <span className="trademark">Formulare Digitale 2024</span>
  </div>
</footer>
  );
};

export default Footer;
