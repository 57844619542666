import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import './App.scss';
import Panel from './components/panel/panel.component';
import FormPage from './components/form-page/formpage.component';
import Header from './components/header/header.component';
import FormularF8 from './formulare/f8-cerere-aut-constr/f8-cerere-autorizatie.formular';
import FormularF1 from './formulare/f1-Cerere-pentru-emiterea-certificatului-de-urbanism/f1-cerere-certificat-urbanism';
import HomePage from './components/home-page/HomePage';
import PrivacyPolicy from './components/privacy-policy/privacypolicy';
import Footer from './components/footer/footer';
import TagManager from 'react-gtm-module'; // Import TagManager

const AppContent = () => {
  const location = useLocation();
    // Check if the current path is one of the form pages
    const isFormPage = location.pathname === './formulare/f1-Cerere-pentru-emiterea-certificatului-de-urbanism/f1-cerere-certificat-urbanism' ||
    location.pathname === '/formulare/f8-cerere-aut-constr/f8-cerere-autorizatie.formular';


  return (
    <div className="app">
      <Header />
      <div className={`main-container ${isFormPage ? 'form-page' : ''}`}>
      {location.pathname !== '/' && <Panel side="left" />}
        <div className="content">
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/formular8-CERERE-pentru-emiterea-autorizației-de-construire/desființare" render={() => <FormPage formular={<FormularF8 />} />} />
          <Route path="/formular1-CERERE-pentru-emiterea-certificatului-de-urbanism" render={() => <FormPage formular={<FormularF1 />} />} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          </Switch>
          {location.pathname !== '/' && <Panel side="right" />}
        </div>
       
      </div>
      <Footer />
    </div>
  );
};


const App = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-NKFR77HD' // Replace with your GTM ID
    };
    TagManager.initialize(tagManagerArgs);
  }, []);


  useEffect(() => {
    if (document.querySelector('.form-page')) {
      const viewport = document.querySelector('meta[name=viewport]');
      if (viewport) {
        viewport.setAttribute('content', 'width=device-width, initial-scale=0.7, maximum-scale=1.0, user-scalable=yes');
      }
    }
  }, []); // Empty dependency array

  useEffect(() => {
    console.log("Viewport adjustment effect running");
    const formPage = document.querySelector('.form-page');
    if (formPage) {
      console.log("Form page detected");
      const viewport = document.querySelector('meta[name=viewport]');
      if (viewport) {
        console.log("Viewport meta tag found, current content:", viewport.getAttribute('content'));
        viewport.setAttribute('content', 'width=device-width, initial-scale=0.7, maximum-scale=1.0, user-scalable=yes');
        console.log("Viewport content updated to:", viewport.getAttribute('content'));
      } else {
        console.log("Viewport meta tag not found");
      }
    } else {
      console.log("Form page not detected");
    }
  }, []);
  

  return (
    <Router>
      <AppContent />
    </Router>
  );
};



export default App;

